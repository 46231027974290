<template>
  <div class="home">
    <div class="statistic-block">
      <div class="box-card">
        <span class="statistic-icon" style="color: #2dcee3">
          <img class="emoji" src="../../public/img/moyai_1f5ff.svg" />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{ statistic.requestsToday }}</span>
          <span class="statistic-text">Всього питань за сьогодні</span>
        </div>
      </div>
      <div class="box-card">
        <span class="statistic-icon" style="color: #2dcee3">
          <img class="emoji" src="../../public/img/flexed-biceps_1f4aa.svg" />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{
            statistic.userRequestsToday
          }}</span>
          <span class="statistic-text">Сьогодні ви відповіли</span>
          <!-- <span
            class="statistic-description"
            v-if="statistic.requestsToday && statistic.userRequestsToday"
            >Це
            {{
              (statistic.requestsToday / statistic.userRequestsToday) * 100
            }}%</span
          > -->
        </div>
      </div>
      <div class="box-card">
        <span class="statistic-icon" style="color: #2dcee3">
          <img
            class="emoji"
            src="../../public/img/person-with-folded-hands.svg"
          />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{
            statistic.totalCompletedRequests
          }}</span>
          <span class="statistic-text">Всього відповідей</span>
        </div>
      </div>
    </div>

    <div class="statistic-block">
      <div class="box-card" v-if="$store.state.user.role === 'admin'">
        <span class="statistic-icon" style="color: #2dcee3">
          <img
            class="emoji"
            src="../../public/img/smiling-face-with-halo_1f607.svg"
          />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{ statistic.totalUsers }}</span>
          <span class="statistic-text">Всього користурачів</span>
        </div>
      </div>
      <div class="box-card">
        <span class="statistic-icon" style="color: #2dcee3">
          <img
            class="emoji"
            src="../../public/img/grinning-face-with-star-eyes_1f929.svg"
          />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{ statistic.usersToday }}</span>
          <span class="statistic-text">Сьогодні долучилось</span>
          <!-- <span
            class="statistic-description"
            v-if="statistic.totalUsers && statistic.usersToday"
            >Це
            {{
              (statistic.usersToday / statistic.totalUsers) * 100
            }}%</span
          > -->
        </div>
      </div>
      <div class="box-card">
        <span class="statistic-icon" style="color: #2dcee3">
          <img
            class="emoji"
            src="../../public/img/sign-of-the-horns_1f918.svg"
          />
        </span>
        <div class="statistic-info">
          <span class="statistic-number">{{
            statistic.uniqueUsersMadeRequest
          }}</span>
          <span class="statistic-text">Юніків запитало</span>
          <span
            class="statistic-description"
            v-if="statistic.totalUsers && statistic.uniqueUsersMadeRequest"
            >Це
            {{
              (
                (statistic.uniqueUsersMadeRequest / statistic.totalUsers) *
                100
              ).toFixed(2)
            }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      statistic: {
        requestsToday: 0,
        userRequestsToday: 0,
        totalCompletedRequests: 0,
        totalUsers: 0,
        usersToday: 0,
        uniqueUsersMadeRequest: 0,
      },
    };
  },
  mounted() {
    this.getTodayRequests();
  },
  methods: {
    async getTodayRequests() {
      const id = this.$store.state.user._id;
      const { data: requestData } = await this.$http.get(
        `/api/statistic/request/${id}`
      );
      const { data: userData } = await this.$http.get(
        `/api/statistic/user/${id}`
      );
      this.statistic.requestsToday = requestData.todayRequestCount;
      this.statistic.userRequestsToday = requestData.todayUsersRequestCount;
      this.statistic.totalCompletedRequests =
        requestData.totalCompletedRequests;

      this.statistic.totalUsers = userData.totalUsers;
      this.statistic.usersToday = userData.usersToday;
      this.statistic.uniqueUsersMadeRequest = userData.uniqueUsersMadeRequest;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  padding: 20px;
}
h3 {
  margin: 40px 0 0;
}
.statistic-block {
  display: flex;
  margin-top: 35px;
}
.box-card {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: #404e67;
  font-family: Montserrat, Arial, sans-serif;
  margin-right: 20px;
}
.statistic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.statistic-icon {
  display: inline-flex;
  font-size: 42px;
  width: 54px;
  height: 54px;
}
.emoji {
  height: 100%;
  width: 100%;
}
.statistic-number {
  font-size: 22px;
}
.statistic-text {
  font-size: 14px;
  margin-top: 10px;
}
.statistic-description {
  font-size: 12px;
  margin-top: 10px;
}
</style>
