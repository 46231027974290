<template>
  <div class="support-statistic">
    <span class="page-title">Статистика сапортів</span>

    <div class="statistic-data">
      <div class="grid-wrapeer">
        <div class="time-block">
          <span class="time-block-time">Проміжок часу</span>
          <el-date-picker
            v-model="filters.dateRange"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
            @change="getSupportedStatistic"
          >
          </el-date-picker>
        </div>
        <el-table :data="data" style="width: 100%" :border="true">
          <el-table-column prop="name" label="Name" width="180">
            <template slot="header" slot-scope="scope">
              <span>Ім'я</span>
              <el-input
                v-model="filters.name"
                size="mini"
                placeholder="Type to search"
                clearable
                @input="getSupportedStatistic"
              />
            </template>
            <template slot-scope="scope">
              <span @click="showInfo(scope.row)" style="cursor: pointer">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="Кількість запитів" prop="value" />
          <el-table-column label="Лайки" prop="likes" />
          <el-table-column label="Дизлайки" prop="dislikes" />
          <el-table-column label="Відгуків" prop="reviews" />
        </el-table>
      </div>

      <div class="chart-wrapper">
        <div style="width: 100%; height: 100%">
          <IEcharts :option="option" :loading="loading" />
        </div>
      </div>
    </div>
    <PersonalStatisticModal :visible="infoDialogVisible" @hideModal="infoDialogVisible = false" :user="user"/>
  </div>
</template>

<script>
import IEcharts from "vue-echarts-v3/src/full.js";
import PersonalStatisticModal from './modal/PersonalStatisticModal.vue'

export default {
  name: "Statistic",
  components: {
    IEcharts,
    PersonalStatisticModal
  },
  data() {
    return {
      data: [],
      loading: true,
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 10,
          top: 20,
          bottom: 20,
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            center: ["25%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "Сьогодні",
            onClick(picker) {
              const end = new Date();
              const timestampEnd = end.setHours(23, 59, 59, 0);
              const start = new Date();
              const timestampStart = start.setHours(0, 0, 0, 0);
              picker.$emit("pick", [timestampStart, timestampEnd]);
            },
          },
          {
            text: "Вчора",
            onClick(picker) {
              const end = new Date();
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours(23, 59, 59, 0);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours(0, 0, 0, 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Минулий тиждень",
            onClick(picker) {
              const end = new Date();
              end.setHours(23, 59, 59, 0);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              start.setHours(0, 0, 0, 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Минулий місяць",
            onClick(picker) {
              const end = new Date();
              end.setHours(23, 59, 59, 0);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              start.setHours(0, 0, 0, 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Увесь час",
            onClick(picker) {
              const end = new Date();
              // Need coercion to timestamp
              const endInStamp = end.setHours(23, 59, 59, 0);
              const start = new Date();
              start.setFullYear(2022, 0, 1);
              start.setHours(0, 0, 0, 0);
              picker.$emit("pick", [start.getTime(), endInStamp]);
            },
          },
        ],
      },
      filters: {
        name: "",
        dateRange: [],
      },
      infoDialogVisible: false,
      user: {}
    };
  },
  mounted() {
    this.getSupportedStatistic();
  },
  methods: {
    async getSupportedStatistic() {
      for (const key in this.filters) {
        if (!this.filters[key].length) delete this.filters[key];
      }
      const { data } = await this.$http.get("/api/statistic/support", {
        params: { filters: this.filters },
      });
      this.data = data;
      this.loading = false;
      this.option.series[0].data = data;
    },
    showInfo(user){
      this.user = user;
      this.infoDialogVisible = true;
    },
    hideModal(){
      this.infoDialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.statistic-data {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px;
}
.page-title {
  font-size: 42px;
  margin-top: 30px;
}
.grid-wrapeer {
  width: 800px;
}
.time-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.time-block-time {
  display: inline-flex;
  margin-bottom: 10px;
}

.chart-wrapper {
  width: 900px;
  height: 500px;
}
</style>