<template>
    <div class="footer-wrapper">
        <slot name="content"/>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
.footer-wrapper {
  width: 85vw;
  height: 50px;
  margin-left: -20px;
  position: absolute;
  bottom: 0;
  background: #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>