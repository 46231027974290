<template>
  <div class="users">
    <div class="users-table">
      <el-table :data="users" style="width: 100%" :border="true">
        <!-- <el-table-column>
        <template slot-scope="scope">
          <img :src="scope.row.chat_picture" class="chat-image" />
        </template>
      </el-table-column> -->
        <el-table-column prop="name" label="Name" width="180">
          <template slot="header" slot-scope="scope">
            <span>Name</span>
            <el-input v-model="filters.name" size="mini" placeholder="Type to search" clearable @input="getUsers" />
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="Nickname" width="180">
          <template slot="header" slot-scope="scope">
            <span>Нікнейм</span>
            <el-input v-model="filters.nickname" size="mini" placeholder="Type to search" clearable @input="getUsers" />
          </template>
          <template slot-scope="scope">
            <a :href="`https://telegram.me/${scope.row.nickname}`" target="_blank">{{ `@${scope.row.nickname}` }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="Role" width="180">
          <template slot="header" slot-scope="scope">
            <span>Role</span>
            <el-select v-model="filters.role" placeholder="Select" @change="getUsers" clearable multiple>
              <el-option v-for="item in rolesList" :key="item.value" :label="item.text" :value="item.value">
              </el-option>
            </el-select>
          </template>

          <template slot-scope="scope">
            <el-tag size="medium" :type="roleEnum[scope.row.role]" effect="dark">{{ scope.row.role }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="practices" label="Практики" width="180">
          <template slot="header" slot-scope="scope">
            <span>Практики</span>
            <el-select v-model="filters.practices" placeholder="Select" @change="getUsers" clearable multiple>
              <el-option v-for="item in practiceList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>

          <template slot-scope="scope">

            <div v-if="scope.row.role === 'support' || scope.row.role === 'admin' || scope.row.role === 'editor'" class="practice-column">

              <el-tag v-for="(practice, index) in scope.row.practiceId" :key="practice" size="medium" type="warning"
                effect="dark" closable @close="deletePractice(practice, scope.row._id)">{{ practiceLabel(practice) }}
              </el-tag>

              <el-popover placement="bottom" width="300" trigger="click">
                <div style="text-align: right; margin: 0">
                  <el-tag v-for="practice in practiceFilteredList(scope.row.practiceId)" :key="practice.value" class="practice-tag-add" @click="addPractice(practice.value, scope.row._id)">
                    {{ practice.label }}
                  </el-tag>
                </div>
                <el-button type="primary" slot="reference" size="mini">+ Додати</el-button>
              </el-popover>

            </div>
          
          </template>
        </el-table-column>
        <el-table-column label="User Settings">
          <template slot-scope="scope">
            <SettingsColumn :data="scope.row" @updateRole="updateRole" @block="block" @unblock="unblock" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TheFooter>
      <template slot="content">
        <el-pagination background layout="prev, pager, next" :total="pagination.total" :page-size="pagination.perPage"
          @current-change="paginationChange">
        </el-pagination>
      </template>
    </TheFooter>
  </div>
</template>

<script>
import SettingsColumn from "./table/SettingsColumn.vue";
import TheFooter from "../../base/TheFooter.vue";
export default {
  name: "Users",
  components: { SettingsColumn, TheFooter },
  data() {
    return {
      users: [],
      practiceList: [],
      pagination: {
        perPage: 20,
        page: 1,
        total: 0,
      },
      roleEnum: {
        user: "success",
        support: "warning",
        editor: "info",
        admin: "danger",
      },
      rolesList: [
        { text: "Користувач", value: "user" },
        { text: "Сапорт", value: "support" },
        { text: "Редактор", value: "editor" },
        { text: "Адмін", value: "admin" },
      ],
      filters: {
        name: "",
        nickname: "",
        role: [],
      },
    };
  },
  created() {
    this.getPractices();
    this.getUsers();
  },
  methods: {
    async getUsers() {
      let params = {
        limit: this.pagination.perPage,
        offset: this.pagination.perPage * (this.pagination.page - 1),
        filters: {},
      };

      for (const key in this.filters) {
        if (!this.filters[key].length) delete this.filters[key];
      }

      Object.assign(params.filters, this.filters);

      const { data } = await this.$http.get("/api/user/all", { params });
      this.pagination.total = data.total;
      this.users = data.data;
    },
    async getPractices() {
      const params = { status: "active" };
      const { data } = await this.$http.get("/api/practice", { params });
      this.practiceList = data.map((practice) => {
        return {
          value: practice._id,
          label: practice.name,
        };
      });
      console.log(this.practiceList);
    },
    async updateRole(data) {
      const response = await this.$http.put(`/api/user/${data.id}`, {
        role: data.role,
      });
      if (response) {
        this.$message({
          message: "Роль успішно змінена!",
          type: "success",
        });
        this.getUsers();
      }
    },
    async block(id) {
      console.log(id);
      const { data } = await this.$http.put(`/api/user/block/${id.id}`);
      if (data === "ok") {
        this.$message({
          message: "Користувача заблоковано на 1 годину",
          type: "success",
        });
        this.getUsers();
      }
    },
    async unblock(id) {
      console.log(id);
      const { data } = await this.$http.put(`/api/user/${id.id}`, {
        status: "idle",
      });
      if (data) {
        this.$message({
          message: "Користувача розблоковано!",
          type: "success",
        });
        this.getUsers();
      }
    },

    paginationChange(data) {
      this.pagination.page = data;
      this.getUsers();
    },
    practiceLabel(id) {
      const practice = this.practiceList.find(
        (practice) => practice.value === id
      );
      return practice.label;
    },
    async deletePractice(id, userId) {
      const params = { id, userId };
      const { data } = await this.$http.delete(`/api/user/practice`, {
        params,
      });
      if (data.status === true) {
        this.$message({
          message: "Практика видалена",
          type: "success",
        });
        this.getUsers();
      }
    },
    async addPractice(id, userId) {
      const params = { id, userId };
      const { data } = await this.$http.post(`/api/user/practice`, { params });
      if (data.status === true) {
        this.$message({
          message: "Практика додана",
          type: "success",
        });
        this.getUsers();
      }else{
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    practiceFilteredList(existedPracticesId){
      return this.practiceList.filter(practice => !existedPracticesId.includes(practice.value)) ;
    }
  },
};
</script>

<style lang="scss">
.users {
  padding: 20px;
}

.users-table {
  margin-bottom: 50px;
}

.practice-tag-add{
  cursor: pointer;
  &:hover{
    transform: scale(1.1);
  }
}
.practice-column{
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
}
</style>
