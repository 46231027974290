<template>
  <div>Login Check</div>
</template>

<script>
import crypto from "crypto-js";
export default {
  mounted() {
    this.auth();
  },
  methods: {
    async auth() {
      const decrypt = crypto.AES.decrypt(
        this.$route.params.token,
        process.env.VUE_APP_SECRET_KEY
      ).toString(crypto.enc.Utf8);
      const { data } = await this.$http.post("/api/auth", JSON.parse(decrypt));
      if (data.token) {
        localStorage.setItem("user-token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        await this.$store.commit("setToken", data.token);
        await this.$store.commit("setUser", data.user);
        this.$router.push("/");
      }else{
        console.error('Token error')
      }
    },
  },
};
</script>

<style lang="scss">
.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .login-page-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>