<template>
    <div class="mailing">
        <h1 class="page-title">Розсилки</h1>

    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>