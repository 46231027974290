<template>
  <div class="chat-history">
    <div class="chat-history-table">
      <el-table :data="chats" stripe style="width: 100%">
        <el-table-column prop="dateEnd" label="Дата закінчення" width="190">
          <template slot="header" slot-scope="scope">
            <span>Дата закінчення</span>
            <el-date-picker
              style="width: 175px; height: 30px"
              v-model="filters.dateEnd"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date"
              :default-time="['00:00:00', '23:59:59']"
              @change="filterChange"
            >
            </el-date-picker>
          </template>
          <template slot-scope="scope">
            <span>{{ getDate(scope.row.dateEnd) }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="dateStart" label="Дата початку" width="190">
          <template slot="header" slot-scope="scope">
            <span>Дата закінчення</span>
            <el-date-picker
              style="width: 175px; height: 30px"
              v-model="filters.dateStart"
              type="datetimerange"
              start-placeholder="Start date"
              end-placeholder="End date"
              :default-time="['00:00:00', '23:59:59']"
              @change="filterChange"
            >
            </el-date-picker>
          </template>
          <template slot-scope="scope">
            <span>{{ getDate(scope.row.dateStart) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="user.name"
          label="Питав"
          width="180"
          v-if="$store.state.user.role === 'admin'"
        >
          <template slot="header" slot-scope="scope">
            <span>Питав</span>
            <el-input
              v-model="filters.user"
              size="mini"
              placeholder="Type to search"
              clearable
              @input="filterChange"
            />
          </template>
        </el-table-column>

        <el-table-column prop="support.name" label="Відповідав" width="180">
          <template slot="header" slot-scope="scope">
            <span>Відповідав</span>
            <el-input
              v-model="filters.support"
              size="mini"
              placeholder="Type to search"
              clearable
              @input="filterChange"
            />
          </template>
        </el-table-column>

        <el-table-column prop="message" label="Питання">
          <template slot="header" slot-scope="scope">
            <span>Питання</span>
            <el-input
              v-model="filters.message"
              size="mini"
              placeholder="Type to search"
              clearable
              @input="filterChange"
            />
          </template>
          <template slot-scope="scope">
            <span>{{ getRequestText(scope.row.message) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="messageCount"
          label="Кількість повідомлень"
          width="90"
        >
        </el-table-column>

        <el-table-column prop="practice.name" label="Практика" width="120">
          <template slot="header" slot-scope="scope">
            <span>Практика</span>
            <el-select
              v-model="filters.practice"
              placeholder="Обрати"
              @change="filterChange"
              clearable
            >
              <el-option
                v-for="item in practices"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </template>
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.practice._id"
              @change="
                changePractice(scope.row.practice._id, scope.row.requestId)
              "
            >
              <el-option
                v-for="item in practices"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Відкрити чат" width="90">
          <template slot-scope="scope">
            <span @click="openChat(scope.row._id)" style="cursor: pointer"
              >Чат</span
            >
            <span
              @click="openChatInNewTab(scope.row._id)"
              style="cursor: pointer; margin-left: 15px"
            >
              <i class="el-icon-right"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TheFooter>
      <template slot="content">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          @current-change="paginationChange"
        >
        </el-pagination>
      </template>
    </TheFooter>
  </div>
</template>

<script>
import TheFooter from "../../base/TheFooter.vue";
import ExportGenerator from "../../helper/ExportGenerator";
export default {
  components: { TheFooter },
  data() {
    return {
      chats: [],
      practices: [],
      pagination: {
        perPage: 20,
        page: 1,
        total: 0,
      },
      filters: {
        user: "",
        support: "",
        practice: "",
        message: "",
        dateStart: [],
        dateEnd: [],
      },
    };
  },

  created() {
    this.getChatHistory();
    this.getPractices();
  },

  methods: {
    async getChatHistory() {
      const params = {
        limit: this.pagination.perPage,
        offset: this.pagination.perPage * (this.pagination.page - 1),
        filters: {},
      };

      for (const key in this.filters) {
        if (!this.filters[key]?.length) delete this.filters[key];
      }

      Object.assign(params.filters, this.filters);

      const response = await this.$http.get("/api/chat/history", { params });
      this.chats = response.data.data;
      this.pagination.total = response.data.total;
    },
    async getPractices() {
      const { data } = await this.$http.get("/api/practice");
      this.practices = data;
      this.practices.push({ name: "Інше", _id: "not set" });
    },
    filterChange() {
      console.log("change");
      this.pagination.page = 1;
      this.getChatHistory();
    },
    async changePractice(practiceId, requestId) {
      if (practiceId === "not set") practiceId = null;

      const response = await this.$http.put(`/api/request/${requestId}`, {
        practiceId,
      });
      if (response.data._id) {
        this.$message({
          message: "Практика успішно змінена",
          type: "success",
        });
      }
    },
    paginationChange(data) {
      this.pagination.page = data;
      this.getChatHistory();
    },
    getDate(date) {
      return new Date(date).toLocaleString("uk-UA");
    },
    openChat(id) {
      this.$router.push(`/chat/${id}`);
    },
    openChatInNewTab(id) {
      let routeData = this.$router.resolve(`/chat/${id}`);
      window.open(routeData.href, "_blank");
    },
    getRequestText(text) {
      if (text && text.length > 50) {
        return text.substring(0, 50) + "...";
      } else if (text) {
        return text;
      } else {
        return "empty";
      }
    },
    chatsExport() {
      const generator = new ExportGenerator("CSV");
      const dataToExport = [];
      for (let i = 0; i < chats.length; i++) {
        const item = {
          "Дата/время": this.getDate(chats[i].date),
          Вопрос: chats[i].request.message,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.chat-history {
  padding: 20px;
}
.chat-history-table {
  margin-bottom: 50px;
}
</style>