<template>
  <div class="project-creation-modal-wrapper">
    <div class="project-creation-modal">
      <el-dialog
        :title="title"
        :visible.sync="isShow"
        @close="$emit('close')"
        width="60%"
        class="reject-modal"
      >
        <div class="modal-row flex-direction-column">
          <el-input
            class="project-creation-modal-input"
            :class="{ 'input-error': hasNameError }"
            placeholder="Назва"
            v-model="project.name"
          >
          </el-input>
          <span class="error-text" v-if="hasNameError"
            >Назва повинна бути вказана</span
          >
        </div>

        <div class="modal-row flex-direction-column">
          <el-input
            class="project-creation-modal-textarea"
            :class="{ 'input-error': hasDescriptionError }"
            type="textarea"
            :rows="2"
            placeholder="Опис"
            v-model="project.description"
          ></el-input>
          <span class="error-text" v-if="hasDescriptionError"
            >Опис повинен бути вказаний</span
          >
        </div>

        <div class="modal-row">
          <el-select
            v-model="project.practiceIds"
            placeholder="Практики"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in practices"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            @click="openNewPracticeModal"
            :style="{ 'margin-left': '10px' }"
            >Додати практики</el-button
          >
        </div>

        <div class="modal-row">
          <el-select
            v-model="project.userIds"
            multiple
            collapse-tags
            filterable
            placeholder="Сапорти"
          >
            <el-option
              v-for="item in users"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </div>

        <div class="modal-row">
          <el-switch
            v-model="project.status"
            active-text="Увімкнути"
            inactive-text="Вимкнути"
            active-value="active"
            inactive-value="inactive"
            v-if="type === 'update'"
          >
          </el-switch>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="close">Відміна</el-button>
          <el-button type="primary" @click="save">Зберегти</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    type: String,
    users: Array,
    practices: Array,
    currentProject: Object,
  },
  data() {
    return {
      isShow: false,
      title: "",
      hasNameError: false,
      hasDescriptionError: false,
      project: {
        _id: "",
        name: "",
        description: "",
        practiceIds: [],
        userIds: [],
        status: "inactive",
      },
    };
  },
  watch: {
    visible(v) {
      this.isShow = v;
    },
    currentProject(v) {
      if (Object.keys(this.project).length) {
        console.log(Object.keys(this.project).length);
        this.project = v;
      }
    },
    type(v) {
      console.log(v);
      this.title = v === "create" ? "Створити новий проект" : "Оновити проект";
    },
  },
  methods: {
    save() {
      if (!this.project.name) {
        this.hasNameError = true;
        return;
      } else {
        this.hasNameError = false;
      }
      if (!this.project.description) {
        this.hasDescriptionError = true;
        return;
      } else {
        this.hasDescriptionError = false;
      }
      if (this.type === "create") {
        this.$emit("save", this.project);
      } else {
        console.log(this.project);
        this.$emit("update", this.project);
      }
    },
    close() {
      this.project = {
        _id: "",
        name: "",
        description: "",
        practiceIds: [],
        userIds: [],
        status: "inactive",
      };
      this.$emit("close");
    },
    openNewPracticeModal() {
      this.$emit("openNewPracticeModal");
    },
  },
};
</script>

<style lang="scss">
.project-creation-modal-input {
  width: 60%;
}
.project-creation-modal-textarea {
  margin: 25px 0;
  width: 60%;
}
</style>