<template>
  <div class="statistic">
    <span class="page-title">Статистика</span>
  </div>
</template>

<script>
export default {
  name: "Statistic",
  data() {
    return {
      data: []
    };
  },
  mounted() {
    this.getSupportedStatistic();
  },
  methods: {
    async getSupportedStatistic() {
      const { data } = await this.$http.get("/api/statistic/support");
      this.data = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 42px;
  margin-top: 30px;
}

</style>