import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Users from "../components/users/all-users/Users.vue";
import ChatHistory from '../components/chat/history/ChatHistory.vue'
import Chat from '../components/chat/Chat.vue'
import Requests from '../components/users/requests/Requests.vue'
import Login from '../components/login/Login'
import LoginCheck from '../components/login/LoginCheck'
import Statistic from '../components/statistic/Statistic'
import Projects from '../components/projects/Projects'
import Mailing from '../components/mailing/Mailing'
import SupportStatistic from '../components/statistic/support/SupportStatistic';
import RequestStatistic from '../components/statistic/request/RequestStatistic';
import UVSStatistic from '../components/statistic/projects/UVS';

import store from '@/store';

// const isAuthenticated = store.getters['isAuthenticated'];

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/users/all',
    name: 'users',
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: '/users/requests',
    name: 'Requests',
    component: Requests,
    meta: { requiresAuth: true },
  },
  {
    path: '/chat-history',
    name: 'ChatHistory',
    component: ChatHistory,
    meta: { requiresAuth: true },
  },
  {
    path: '/chat/:id',
    name: 'Chat',
    component: Chat,
    meta: { requiresAuth: true },
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: Statistic,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { requiresAuth: true },
  },
  {
    path: '/statistic/support',
    name: 'SupportStatistic',
    component: SupportStatistic,
    meta: { requiresAuth: true },
  },
  {
    path: '/statistic/request',
    name: 'RequestStatistic',
    component: RequestStatistic,
    meta: { requiresAuth: true },
  },
  {
    path: '/mailing',
    name: 'Mailing',
    component: Mailing,
    meta: { requiresAuth: true },
  },
  {
    path: '/statistic/UVS',
    name: 'UVSStatistic',
    component: UVSStatistic,
    meta: { requiresAuth: true },
  },

  // Login routes
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },

  {
    path: '/check/:token(.*)*',
    name: 'LoginCheck',
    component: LoginCheck,
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// const loggedIn = ;

router.beforeEach((to, from, next) => {
  if (to.path.substr(1, 5) === 'check') {
    next();
  } else if (to.meta.requiresAuth && !store.getters.isAuthenticated && to.path !== '/login') {
    next({ name: 'Login' });
  } else {
    next();
  }
})

export default router;