<template>
  <div class="practice-creation-modal-wrapper">
    <div class="practice-creation-modal">
      <el-dialog
        title="Додати практику"
        :visible.sync="isShow"
        @close="$emit('close')"
        width="60%"
        class="reject-modal"
      >
        <el-input
          class="project-creation-modal-input"
          placeholder="Назва"
          v-model="practiceName"
        >
        </el-input>

        <span slot="footer" class="dialog-footer">
          <el-checkbox v-model="createAgain" :style="{'margin-right': '10px'}">Створити ще один</el-checkbox>
          <el-button @click="$emit('close')">Відміна</el-button>
          <el-button type="primary" @click="save">Зберегти</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      isShow: false,
      createAgain: false,
      practiceName: "",
    };
  },
  watch: {
    visible(v) {
      this.isShow = v;
    },
  },
  methods: {
    save() {
      this.$emit("save", {name: this.practiceName, createAgain: this.createAgain});
    },
  },
};
</script>

<style lang="scss">
.practice-creation-modal-input {
  width: 60%;
}
.practice-creation-modal-textarea {
  margin: 25px 0;
  width: 60%;
}
</style>