<template>
  <div class="setting-row">
    <el-popover
      placement="bottom"
      :title="role.higherButtonLabel + '?'"
      width="200"
      trigger="click"
      v-model="visiblePopoverIncrease"
      v-if="role.next"
    >
      <div style="text-align: right; margin: 0">
        <el-button
          size="mini"
          type="text"
          @click="visiblePopoverIncrease = false"
          >Ні</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="updateRole(data._id, role.next)"
          >Так</el-button
        >
      </div>
      <el-button type="primary" slot="reference">{{
        role.higherButtonLabel
      }}</el-button>
    </el-popover>

    <el-popover
      placement="bottom"
      title="Ви дійсно хочете позити його в правах?"
      width="200"
      trigger="click"
      v-model="visiblePopoverDecrease"
      v-if="role.prev"
    >
      <div style="text-align: right; margin: 0">
        <el-button
          size="mini"
          type="text"
          @click="visiblePopoverDecrease = false"
          >Ні</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="updateRole(data._id, role.prev)"
          >Так</el-button
        >
      </div>
      <el-button type="danger" slot="reference" style="margin-left: 10px"
        >Понизити</el-button
      >
    </el-popover>

    <el-popover
      placement="bottom"
      title="Заблокувати?"
      width="200"
      trigger="click"
      v-model="visiblePopoverBlock"
      v-if="data.status !== 'blocked'"
      style="margin-left: 10px"
    >
      <div style="text-align: right; margin: 0">
        <el-button
          size="mini"
          type="text"
          @click="visiblePopoverBlock = false"
          >Ні</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="block(data._id)"
          >Так</el-button
        >
      </div>
      <el-button type="danger" slot="reference">Заблокувати</el-button>
    </el-popover>
    <el-popover
      placement="bottom"
      title="Розблокувати?"
      width="200"
      trigger="click"
      v-model="visiblePopoverBlock"
      style="margin-left: 10px"
      v-else
    >
      <div style="text-align: right; margin: 0">
        <el-button
          size="mini"
          type="text"
          @click="visiblePopoverBlock = false"
          >Ні</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="unblock(data._id)"
          >Так</el-button
        >
      </div>
      <el-button type="success" slot="reference">Розблокувати</el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "SettingsColumn",

  props: {
    data: Object,
  },

  watch: {
    data(v) {
      this.role = this.roleList.find((role) => role.role === this.data.role);
    },
  },

  mounted() {
    if (this.data) {
      this.role = this.roleList.find((role) => role.role === this.data.role);
    }
  },

  data() {
    return {
      roleList: [
        {
          role: "user",
          prev: null,
          next: "support",
          higherButtonLabel: "Зробити саппортом",
        },
        {
          role: "support",
          prev: "user",
          next: "editor",
          higherButtonLabel: "Підняти до редактора",
        },
        {
          role: "editor",
          prev: "support",
          next: "admin",
          higherButtonLabel: "Зробити Адміном",
        },
        {
          role: "admin",
          prev: "editor",
          next: null,
        },
      ],
      role: {},
      visiblePopoverIncrease: false,
      visiblePopoverDecrease: false,
      visiblePopoverBlock: false
    };
  },

  methods: {
    updateRole(id, role) {
      this.visiblePopoverIncrease = false;
      this.visiblePopoverDecrease = false;
      this.$emit("updateRole", { id, role });
    },
    block(id){
      this.visiblePopoverBlock = false;
      this.$emit("block", { id });
    },
    unblock(id){
      this.visiblePopoverBlock = false;
      this.$emit("unblock", { id });
    }
  },
};
</script>

<style lang="scss">
</style>