<template>
  <div id="app">
    <div id="app-left-bar" v-if="$store.getters.isAuthenticated">
      <div class="app-left-bar-header" @click="toLink('/')">
        <img src="../public/img/logo.png" class="app-left-bar-logo" />
        <h2 class="app-left-bar-header-title">ЮрШтаб 🇺🇦</h2>
      </div>
      <nav class="app-left-bar-nav">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#eeeeee"
        >
          <el-submenu index="1" v-if="$store.state.user.role === 'admin'">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>Користувачі</span>
            </template>
            <el-menu-item index="1-2"
              ><router-link to="/users/all"
                >Всі Користувачі</router-link
              ></el-menu-item
            >
            <el-menu-item index="1-2"
              ><router-link to="/users/requests"
                >Запити</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-chat-line-round"></i>
              <span>Чати</span>
            </template>
            <el-menu-item index="2-1"
              ><router-link to="/chat-history"
                >Історія</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-trophy"></i>
              <span>Статистика</span>
            </template>
            <el-menu-item index="3-1"
              ><router-link to="/statistic/support"
                >Сапортів</router-link
              ></el-menu-item
            >
            <el-menu-item index="3-2"
              ><router-link to="/statistic/request"
                >Запитів</router-link
              ></el-menu-item
            >
            <el-menu-item index="3-3"
              ><router-link to="/statistic/UVS"
                >УВС 💙💛</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-menu-item index="4"
            ><router-link to="/projects"
              ><i class="el-icon-s-cooperation"></i>Проекти</router-link
            ></el-menu-item
          >
          <el-menu-item index="5"
            ><router-link to="/mailing"
              ><i class="el-icon-s-promotion"></i>Розсилки</router-link
            ></el-menu-item
          >
        </el-menu>
      </nav>
    </div>
    <div id="app-content-container" v-if="$store.getters.isAuthenticated">
      <router-view />
    </div>
    <div class="not-authorized-wrapper" v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toLink(link) {
      this.$router.push({ path: link });
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  overflow: hidden;
}

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

.not-authorized-wrapper {
  width: 100%;
  height: 100%;
}

#app-left-bar {
  display: flex;
  position: absolute;
  height: 100vh;
  width: 15%;
  background-color: #eeeeee;
  border-right: #000;
  flex-direction: column;
  // color: #000;
}

.app-left-bar-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.app-left-bar-header-title {
  display: flex;
  margin-left: 15px;
}

.app-left-bar-logo {
  width: 100px;
  height: 100px;
  margin: 15px;
}

.el-submenu__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // color: black;
}

.el-menu-item {
  display: flex;
  color: #303133;
}

#app-content-container {
  margin-left: 15%;
  width: 85%;
  height: 100vh;
  overflow: auto;
}
img {
  width: 100%;
  height: 100%;
}

.modal-row{
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.flex-direction-column{
  flex-direction: column;
}

.error-text{
  font-size: 12px;
  color: #E52B50;
  width: fit-content;
}

.input-error{
  .el-input__inner{
    border-color: #E52B50;
  }
  .el-textarea__inner{
    border-color: #E52B50;
  }
}

</style>
