import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    user: JSON.parse(localStorage.getItem('user')) || {},
    token: localStorage.getItem('user-token') || '',
  },
  mutations:{
    setToken(state, token){
      state.token = token;
    },
    setUser(state, user){
      state.user = user;
    }
  },
  getters:{
    isAuthenticated(state){
      return !!state.token;
    },
    getToken(state){
      return state.token;
    }
  }
})
