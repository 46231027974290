import XLSX from 'xlsx';

export default class ExportFileGenerator {

    #FILE_HTML = 'HTML';
    #FILE_TEXT = 'TEXT';
    #FILE_CSV = 'CSV';
    #FILE_XLS = 'Excel 95+';
    #FILE_XLSX = 'Excel 2007+';

    #FILE_SETUP_MAP = {
        [this.#FILE_CSV] : 'csv',
        [this.#FILE_HTML] : 'html',
        [this.#FILE_XLS] : 'xls',
        [this.#FILE_XLSX] : 'xlsx',
        [this.#FILE_TEXT]: 'txt'
    };

    #fileType;
    #sheet;

    constructor(fileType) {
        this.#fileType = fileType;

        if (!this.#FILE_SETUP_MAP[this.#fileType]) {
            throw new Error(`Unsupported file type: ${fileType}`);
        }
    }

    appendData(json) {
        if (!this.#sheet) {
            this.#sheet = XLSX.utils.json_to_sheet([json]);
        } else {
            XLSX.utils.sheet_add_json(this.#sheet, [json], {origin: -1, skipHeader: true});
        }
    }

    download() {
        const book = XLSX.utils.book_new();

        book.SheetNames[0] = 'export';
        book.Sheets.export = this.#sheet;
        const format = this.#FILE_SETUP_MAP[this.#fileType];

        XLSX.writeFile(book, `export.${Date.now()}.${format}`,{
            bookType: format,
            type: 'file',
        });
    }
}