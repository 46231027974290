<template>
  <div id="user-requests">
    <div class="box-card" v-for="request in requests" :key="request._id">
      <div class="box-header">
        <span class="box-header-title">{{ request.name }}</span>
      </div>
      <div class="box-body">
        <div class="request-item">
          <span class="request-item-title">Місце роботи: </span>
          <span>{{ request.workPlace }}</span>
        </div>
        <div class="request-item">
          <span class="request-item-title">Соц. мережі: </span>
          <span>{{ request.socialMedia }}</span>
        </div>
        <div class="request-item">
          <span class="request-item-title">Коментар: </span>
          <span>{{ request.message }}</span>
        </div>
      </div>
      <div class="box-footer">
        <div
          class="box-footer-button_reject"
          @click="viewDialog(request.userId)"
        >
          Відхилити
        </div>
        <div class="box-footer-button_confirm" @click="confirm(request.userId)">
          Прийняти
        </div>
      </div>
    </div>
    <el-dialog
      title="Напишіть чому кандидату відказано"
      :visible.sync="rejectDialogVisible"
      width="30%"
      class="reject-modal"
    >
      <el-input placeholder="Чому?..." v-model="rejectMessage" clearable>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">Відміна</el-button>
        <el-button type="primary" @click="reject">Відправити</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UserRequests",
  data() {
    return {
      requests: [],
      rejectDialogVisible: false,
      rejectMessage: "",
      rejectingRequestId: null,
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    async getRequests() {
      const { data } = await this.$http.get("/api/support-request/pending", {
        filters: { status: "pending" },
      });
      this.requests = data;
    },
    async confirm(id) {
      const response = await this.$http.put(`/api/user/${id}`, {
        role: "support",
      });
      if (response) {
        this.$message({
          message: "Заявка схвалена",
          type: "success",
        });
        this.getRequests();
      }
    },
    viewDialog(id) {
      this.rejectingRequestId = id;
      this.rejectDialogVisible = true;
    },
    async reject() {
      const response = await this.$http.post(
        `/api/support-request/reject/${this.rejectingRequestId}`,
        { message: this.rejectMessage }
      );
      if (response) {
        this.$message({
          message: "Заявка відхилена",
          type: "success",
        });
        this.getRequests();
        this.rejectingRequestId = null;
        this.rejectDialogVisible = false;
        this.rejectMessage = "";
      }
    },
  },
};
</script>

<style lang="scss">
.reject-modal {
  font-family: Montserrat, Arial, sans-serif;
}
#user-requests {
  padding: 20px;
}
.box-card {
  width: 350px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, Arial, sans-serif;
  margin-right: 20px;
}
.box-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #00b5b8;
  color: #fff;
}
.box-header-title {
  margin-left: 10px;
}
.box-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.request-item {
  margin-top: 15px;
}
.request-item-title {
  font-weight: bold;
}

.box-footer {
  width: 100%;
  height: 50px;
  display: flex;
}
.box-footer-button {
  &_reject {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    color: #fff;
    background-color: #ff6275;
    cursor: pointer;
    &:hover {
      background-color: #ff6d80;
      text-decoration: underline;
    }
  }
  &_confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    color: #fff;
    background-color: #10c888;
    cursor: pointer;
    &:hover {
      background-color: #13ce92;
      text-decoration: underline;
    }
  }
}
</style>