<template>
  <div class="personal-statistic-modal">
    <el-dialog
      :title="`Чати з відмітками - ${user.name}`"
      :visible.sync="modalVisible"
      width="50%"
      class="reject-modal"
      @close="$emit('hideModal')"
    >
      <el-tabs type="border-card" v-model="currentTab">
        <el-tab-pane name="likes">
          <span slot="label"
            ><img
              class="emoji"
              src="../../../../../public/img/thumbs-up-sign_1f44d.png"
            />
            Лайки</span
          >
          <el-table :data="chats" border style="width: 100%">
            <el-table-column
              prop="updatedAt"
              label="Дата закінчення"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ getDate(scope.row.updatedAt) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="message" label="Питання">
              <template slot-scope="scope">
                <span style="word-break: break-word">{{
                  scope.row.message
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Відкрити чат" width="90">
              <template slot-scope="scope">
                <span
                  @click="openChat(scope.row.chatId)"
                  style="cursor: pointer"
                  >Чат</span
                >
                <span
                  @click="openChatInNewTab(scope.row.chatId)"
                  style="cursor: pointer; margin-left: 15px"
                >
                  <i class="el-icon-right"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane name="dislikes">
          <span slot="label"
            ><img
              class="emoji"
              src="../../../../../public/img/thumbs-down-sign_1f44e.png"
            />
            Дизлайки</span
          >
          <el-table :data="chats" border style="width: 100%">
            <el-table-column
              prop="updatedAt"
              label="Дата закінчення"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ getDate(scope.row.updatedAt) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="message" label="Питання">
              <template slot-scope="scope">
                <span style="word-break: break-word">{{
                  scope.row.message
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Відкрити чат" width="90">
              <template slot-scope="scope">
                <span
                  @click="openChat(scope.row.chatId)"
                  style="cursor: pointer"
                  >Чат</span
                >
                <span
                  @click="openChatInNewTab(scope.row.chatId)"
                  style="cursor: pointer; margin-left: 15px"
                >
                  <i class="el-icon-right"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane name="reviews">
          <span slot="label"
            ><img
              class="emoji"
              src="../../../../../public/img/smiling-cat-face-with-open-mouth_1f63a.png"
            />
            Відгуки</span
          >
          <el-table :data="chats" border style="width: 100%">
            <el-table-column
              prop="updatedAt"
              label="Дата закінчення"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ getDate(scope.row.updatedAt) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="message"
              label="Питання"
              style="word-break: break-word"
            >
              <template slot-scope="scope">
                <span style="word-break: break-word">{{
                  scope.row.message
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="review" label="Відгук">
              <template slot-scope="scope">
                <span style="word-break: break-word">{{
                  scope.row.review
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Відкрити чат" width="90">
              <template slot-scope="scope">
                <span
                  @click="openChat(scope.row.chatId)"
                  style="cursor: pointer"
                  >Чат</span
                >
                <span
                  @click="openChatInNewTab(scope.row.chatId)"
                  style="cursor: pointer; margin-left: 15px"
                >
                  <i class="el-icon-right"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="pagination.perPage"
            @current-change="paginationChange"
          >
          </el-pagination>
        </div>
        <el-button @click="$emit('hideModal')">Закрити</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PersonalStatisticModal",
  props: {
    visible: { type: Boolean, default: false },
    user: { type: Object, required: true },
  },
  data() {
    return {
      personalData: {},
      chats: [],
      modalVisible: false,
      likes: 0,
      dislikes: 0,
      reviews: 0,
      pagination: {
        perPage: 5,
        page: 1,
        total: 0,
      },
      currentTab: "likes",
    };
  },
  watch: {
    visible(v) {
      this.modalVisible = v;
    },
    modalVisible(v) {
      if (v) this.getPersonalStatistic();
    },
    currentTab(v) {
      this.getPersonalStatistic();
    },
  },
  mounted() {},
  methods: {
    async getPersonalStatistic() {
      const { data } = await this.$http.get(
        `/api/statistic/personal/${this.user._id}`,
        {
          params: {
            tab: this.currentTab,
            offset: this.pagination.perPage * (this.pagination.page - 1),
            limit: this.pagination.perPage,
          },
        }
      );
      this.chats = data.requests;
      this.pagination.total = data.total;
    },
    getDate(date) {
      return new Date(date).toLocaleString("uk-UA");
    },
    openChat(id) {
      this.$router.push(`/chat/${id}`);
    },
    openChatInNewTab(id) {
      let routeData = this.$router.resolve(`/chat/${id}`);
      window.open(routeData.href, "_blank");
    },
    paginationChange(data) {
      this.pagination.page = data;
      this.getPersonalStatistic();
    },
  },
};
</script>

<style lang="scss" scope>
.dialog-footer {
  width: 100%;
}
.pagination {
  display: flex;
}
.emoji {
  width: 24px;
  height: 24px;
  &img {
    width: 100%;
    height: 100%;
  }
}
</style>