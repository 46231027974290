<template>
  <div class="chat">
    <el-page-header @back="back" title="Назад">
      <template slot="content">
        <div class="chat-header-content">
          <a
            :href="`https://telegram.me/${firstUser.nickname}`"
            target="_blank"
            v-if="
              firstUser.nickname !== 'hidden' && $store.state.role === 'admin'
            "
            >{{ `${firstUser.name}` }}</a
          >
          <span v-else>{{ firstUser.name }}</span>
          <el-button size="mini" type="primary" @click="reopenChat"
            >Перевідкрити</el-button
          >
        </div>
      </template>
    </el-page-header>
    <div class="chat-dialog">
      <div v-for="(message, key) in messages" :key="key" class="message">
        <div
          class="container"
          :class="`${firstUser._id !== message.user._id ? 'darker' : null}`"
        >
          <el-card
            :class="`${firstUser._id !== message.user._id ? 'darker' : null}`"
          >
            <div slot="header" class="clearfix">
              <span>{{ message.user.name }}</span>
            </div>
            <el-image
              style="width: 100px; height: 100px"
              v-for="(image, key) of message.files"
              :key="key"
              :src="image.fileId"
              :preview-src-list="[image.fileId]"
              v-if="image.type === 'photo'"
            >
            </el-image>
            <div
              v-for="(file, key) of message.files"
              :key="key"
              v-if="file.type === 'document'"
              class="doc-icon"
            >
              <a class="doc-download-link" :href="file.fileId" download>
                <i class="el-icon-document"></i>
                {{ getDocumentName(file.fileId) }}
              </a>
            </div>
            <div class="message-text text item">
              {{ message.text }}
            </div>
            <span class="time-right">{{ getDate(message.createdAt) }}</span>
          </el-card>
        </div>
      </div>
    </div>
    <TheFooter>
      <template slot="content">
        <el-button size="mini" type="primary" @click="loadHistory"
          >Завантажити істроію</el-button
        >
      </template>
    </TheFooter>
  </div>
</template>

<script>
import TheFooter from "../base/TheFooter.vue";

export default {
  components: { TheFooter },
  data() {
    return {
      messages: [],
      firstUser: {},
    };
  },
  created() {
    this.getChat();
  },
  methods: {
    async getChat() {
      const params = { chatId: this.$route.params.id };
      const { data } = await this.$http.get(`/api/chat/message`, { params });
      this.messages = data;
      this.firstUser = this.messages[0].user;
    },
    async loadHistory(){
      const {data} = await this.$http.get(`/api/chat/history/${this.firstUser._id}`);
      this.messages = data;
      console.log(data);
    },
    getDate(date) {
      return new Date(date).toLocaleString("uk-UA");
    },
    getDocumentName(fileId) {
      return fileId.substring(fileId.lastIndexOf("/") + 1);
    },
    back() {
      this.$router.go(-1);
    },
    async reopenChat() {
      const support = this.$store.state.user;
      const response = await this.$http.post(
        `/api/chat/reopen/${this.$route.params.id}`,
        {
          supportId: support._id,
          userId: this.firstUser._id,
        }
      );

      if (response.data.error) {
        this.$message({
          message: response.data.error,
          type: "error",
        });
        return;
      }

      if (response.data.message) {
        this.$message({
          message: response.data.message,
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.chat {
  padding: 20px;
  height: 100vh;
}
.chat-dialog {
  padding-bottom: 55px;
}
.message {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.container {
  text-align: left;
  width: 400px;
  margin: 10px 0;
}

/* Darker chat container */
.darker {
  margin-left: auto;
  border-color: #ccc;
  &.el-card {
    background-color: #ddd;
  }
}

/* Clear floats */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Style images */
.container img {
  max-width: 80px;
  width: 100%;
  margin-right: 20px;
}

/* Style the right image */
.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}

.message-text {
  margin: 10px;
}

.doc-icon {
  width: 100px;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d12626;
}
.doc-download-link {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.chat-header-content {
  width: calc(80vw - 100px);
  display: flex;
  justify-content: space-between;
}
</style>