import Vue from 'vue'
import App from './App.vue';
import './registerServiceWorker'
import router from './router'
import API from './api';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

console.log('[Client version] 1.5.2');
Vue.config.productionTip = false;
API.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
Vue.prototype.$http = API;

Vue.use(ElementUI);
locale.use(lang);

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faSpinner, faCommentDots, faCircleCheck)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
