<template>
  <div class="uvs-statistic">
    <span class="page-title">УВС статистика запитів</span>
    <div class="statistic-wrapper">
      <el-popconfirm
        confirm-button-text="OK"
        icon="el-icon-info"
        icon-color="red"
        title="На графіку показано тільки закриті питання"
      >
        <el-button slot="reference">Підказка</el-button>
      </el-popconfirm>
      <div style="width: 100%; height: 100%">
        <IEcharts :option="barOption" :loading="loading" />
      </div>
    </div>
    <div class="grid-wrapper">
      <el-table :data="applications" style="width: 100%">
        <el-table-column prop="closeTime" label="Закрито" width="180">
        </el-table-column>
        <el-table-column prop="fullName" label="Ім'я" width="180">
        </el-table-column>
        <el-table-column prop="area" label="Місто" width="100">
        </el-table-column>
        <el-table-column prop="asker" label="Для кого" width="100">
        </el-table-column>
        <el-table-column prop="organization" label="Організація" width="180">
        </el-table-column>
        <el-table-column prop="phoneNumber" label="Номер телефону" width="150">
        </el-table-column>
        <el-table-column prop="message" label="Питання">
          <template slot-scope="scope">
            <span class="uvs-grid-message">{{ scope.row.message }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="openTab" label="Відкрити чат" width="100">
          <template slot-scope="scope">
            <div v-if="!scope.row.chatId">питання ще не обране</div>
            <span
              @click="openChat(scope.row.chatId)"
              style="cursor: pointer"
              v-else
              >Чат</span
            >
            <span
              @click="openChatInNewTab(scope.row.chatId)"
              style="cursor: pointer; margin-left: 15px"
              v-if="scope.row.chatId"
            >
              <i class="el-icon-right"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <TheFooter>
      <template slot="content">
        <span class="footer-info">
          Всього питань: <b>{{ totalRequests }}</b>
        </span>
        <span class="footer-info">
          Заявок залишено: <b>{{ applications.length }}</b>
        </span>
        <el-button type="primary" @click="exportCSV">Експорт даних</el-button>
      </template>
    </TheFooter>
  </div>
</template>

<script>
import IEcharts from "vue-echarts-v3/src/full.js";
import moment from "moment";
import * as XLSX from "xlsx";
import TheFooter from "../../base/TheFooter.vue";

export default {
  components: { IEcharts, TheFooter },
  data() {
    return {
      applications: [],
      totalRequests: 0,
      loading: true,
      barOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.getDaysArrayByMonth(),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Запитів",
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: [0],
          },
        ],
      },
      areaMap: {
        kyiv: "Київ",
        summy: "Суми",
        kharkiv: "Харків",
        dnipro: "Дніпро",
        zp: "Запоріжжя",
        kherson: "Херсон",
        odessa: "Одесса",
        chernihiv: "Чернігів",
        other: "Інша",
      },
    };
  },
  created() {
    this.getApplicationsStatistics();
  },
  methods: {
    async getApplicationsStatistics() {
      const { data } = await this.$http.get("/api/statistic/uvs");
      this.totalRequests = data.totalRequests;
      this.applications = data.applications;

      let barData = [];
      for (let i = 0; i < this.applications.length; i++) {
        const dayOfMonth =
          new Date(this.applications[i].closeTime).getDate() - 1;
        console.log(dayOfMonth);
        if (barData[dayOfMonth]) {
          barData[dayOfMonth]++;
        } else {
          barData[dayOfMonth] = 1;
        }
      }
      this.applications = this.applications.map((application) => ({
        fullName: application.fullName || "немає",
        area: this.areaMap[application.area] || application.area,
        closeTime: application.closeTime ? this.getDate(application.closeTime) : '-',
        asker: application.asker === 'other' ? "Для волонтера" : "Для себе",
        organization: application.organization || "Не вказано",
        phoneNumber: application.phoneNumber || "Не вказано",
        message: application.message,
        chatId: application.chatId,
        helpful: application.helpful || "Не заповнено",
        influence: application.influence || "Не заповнено",
        supportName: application.supportName || "Не заповнено"
      }));
      this.barOption.series[0].data = barData;
      this.loading = false;
    },

    exportCSV() {
      const data = this.applications.map((application) => ({
        "Ім'я": application.fullName,
        "Місто": application.area,
        "Питання закрите": application.closeTime,
        "Для кого": application.asker,
        "Організація": application.organization,
        "Номер телефону": application.phoneNumber,
        "Хто відповідав?": application.supportName,
        "Питання": application.message,
        "Чи було корисно?": application.helpful,
        "Як повпливало?": application.influence
      }));
      console.log(data);

      const sheet = XLSX.utils.json_to_sheet(data);
      const wBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wBook, sheet, "Статистика УВС");
      XLSX.writeFile(wBook, `${new Date()}.csv`);
    },

    getDaysArrayByMonth() {
      let result = [];
      const monthNumber = this.currentMonthNumber() + 1;
      const daysInMonth = moment().daysInMonth();

      for (let i = 1; i <= daysInMonth; i++) {
        result.push(
          `${i}.${monthNumber < 10 ? `0${monthNumber}` : monthNumber}`
        );
      }
      return result;
    },

    openChatInNewTab(id) {
      let routeData = this.$router.resolve(`/chat/${id}`);
      window.open(routeData.href, "_blank");
    },

    currentMonthNumber() {
      const date = new Date();
      const month = date.getMonth();
      return month;
    },

    getDate(date) {
      return new Date(date).toLocaleString("uk-UA");
    },
  },
};
</script>

<style lang="scss" scoped>
.uvs-statistic {
  padding: 20px;
}
.statistic-wrapper {
  height: 500px;
}
.grid-wrapper {
  height: 500px;
  overflow: auto;
}
.uvs-grid-message {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer-info {
  margin-right: 15px;
}
</style>