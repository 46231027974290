<template>
  <div class="login-page">
    <div class="login-page-logo">
      <img src="../../../public/img/logo.png" />
    </div>
    <div class="telegram-widget">
      <el-button type="primary" round @click="verify">Увійти через телеграм</el-button>
      <!-- <vue-telegram-login
        mode="callback"
        :telegram-login="botName"
        @callback="auth"
      /> -->
    </div>
  </div>
</template>

<script>
import { vueTelegramLogin } from "vue-telegram-login";
export default {
  components: { vueTelegramLogin },
  name: "Login",
  data() {
    return {
      botName: process.env.VUE_APP_BOT_NAME,
      authBot: process.env.VUE_APP_AUTH_BOT_NAME
    }
  },
  mounted() {
    console.log(this.$router)
  },
  methods: {
    async verify(){
      window.open(this.authBot, '_blank').focus();
    },
  },
};
</script>

<style lang="scss">
.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .login-page-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>