import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, 
});

instance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('user-token')}`;
    return config;
})

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        localStorage.removeItem("user-token");
        localStorage.removeItem("user");
        window.open(`${process.env.VUE_APP_HOST_URL}/login`)
    } else {
        return Promise.reject(error);
    }
});

export default instance;