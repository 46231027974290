<template>
  <div class="report-modal">
    <el-dialog
      title="Статистика за увесь час"
      :visible.sync="visible"
      width="50%"
    >
    <div class="report-modal-body">

        <div class="loading" v-if="loading">Завантажується</div>
        <div class="practice-row" v-for="practice of practicesData" :key="practice._id">
            <span class="practice-row-name">{{practice.name}}:</span> <span class="practice-row-count">{{practice.count}} {{(practice.count/total*100).toFixed(2)}}%</span>
        </div>
        <div class="practice-row" v-if="total">
            <span class="practice-row-name">Всього:</span> <span class="practice-row-count">{{total}} 100%</span>
        </div>
    </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('close')">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'ReportModal',
    props:{
        visible: Boolean
    },
    data() {
        return {
            practicesData: [],
            loading: false, 
            total: 0
        }
    },
    watch: {
        visible(v){
            if(v){
                this.getPracticesData();
            }
        }
    },
    methods: {
        async getPracticesData(){
            this.loading = true
            const {data} = await this.$http.get("/api/statistic/requests/report");
            this.total = data.reduce((total, practice)=> total + practice.count ,0) 
            this.practicesData = data;
            this.loading = false;
        }
    },
};
</script>

<style lang="scss">
.report-modal-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.practice-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.practice-row-name{
    font-size: 18px;
    color: #000;
}
.practice-row-count{
    font-size: 16px;
    font-weight: bold;
}
</style>