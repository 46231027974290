<template>
  <div class="statistic-request">
    <span class="page-title">Статистика запитів</span>
    <div class="statistic-request-filter">
      <div class="block" v-if="view === 'weekly'">
        <span class="demonstration">Week</span>
        <el-date-picker
          v-model="date"
          type="week"
          format="Week WW"
          placeholder="Pick a week"
          @change="getStatistic"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </div>

      <div class="block" v-else>
        <span class="demonstration">Month</span>
        <el-date-picker v-model="date" type="month" placeholder="Pick a month">
        </el-date-picker>
      </div>
      <div class="report-section">
        <el-button type="primary" @click="modalVisible = true">Репорт за весь час</el-button>
      </div>
    </div>
    <div class="chart-wrapper">
      <div style="width: 100%; height: 100%">
        <IEcharts :option="lineOption" :loading="loading" />
      </div>
    </div>
    <div class="chart-wrapper">
      <div style="width: 100%; height: 100%">
        <IEcharts :option="barOption" :loading="loading" />
      </div>
    </div>
    <ReportModal :visible="modalVisible" @close="modalVisible = false"/>
  </div>
</template>

<script>
import ReportModal from './ReportModal.vue';
import IEcharts from "vue-echarts-v3/src/full.js";

export default {
  name: "RequestStatistic",
  components: { IEcharts, ReportModal },
  data() {
    return {
      statistic: [],
      practices: [],
      date: new Date(),
      view: "weekly",
      datePickerOptions: {
        firstDayOfWeek: 1,
      },
      lineOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: [
            "Нові користувачі",
            "Закриті запити",
            "Відкриті запити",
            "Закрито день в день",
            "Лайки",
            "Дизлайки",
            "Відгуки",
          ],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          data: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
        },
        yAxis: { type: "value" },
        series: [
          {
            name: "Нові користувачі",
            type: "line",
            data: [],
          },
          {
            name: "Закриті запити",
            type: "line",
            data: [],
          },
          {
            name: "Відкриті запити",
            type: "line",
            data: [],
          },
          {
            name: "Закрито день в день",
            type: "line",
            data: [],
          },
          {
            name: "Лайки",
            type: "line",
            data: [],
          },
          {
            name: "Дизлайки",
            type: "line",
            data: [],
          },
          {
            name: "Відгуки",
            type: "line",
            data: [],
          },
        ],
      },
      barOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: [],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Marvel",
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: [320, 332, 301, 334, 390],
          },
        ],
      },
      loading: false,
      modalVisible: false
    };
  },
  created() {
    this.getPractices();
    this.getStatistic();
  },
  methods: {
    async getPractices() {
      const { data } = await this.$http.get("/api/practice");
      const series = [];
      this.practices = data;
      this.practices.push({ name: "Інше", _id: "not set" });
      this.barOption.legend.data = this.practices.map((p) => p.name);
      for (let i = 0; i < this.barOption.legend.data.length; i++) {
        series.push({
          name: this.barOption.legend.data[i],
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        });
      }
      this.barOption.series = series;
    },
    async getStatistic() {
      const { data } = await this.$http.get("/api/statistic/requests", {
        params: { date: this.date, view: this.view },
      });
      this.statistic = data;
      this.lineOption.series = [
        {
          name: "Нові користувачі",
          type: "line",
          data: [],
        },
        {
          name: "Закриті запити",
          type: "line",
          data: [],
        },
        {
          name: "Відкриті запити",
          type: "line",
          data: [],
        },
        {
          name: "Закрито день в день",
          type: "line",
          data: [],
        },
        {
          name: "Лайки",
          type: "line",
          data: [],
        },
        {
          name: "Дизлайки",
          type: "line",
          data: [],
        },
        {
          name: "Відгуки",
          type: "line",
          data: [],
        },
      ];
      for (let i = 0; i < this.barOption.series.length; i++) {
        this.barOption.series[i].data = [];
      }
      for (let i = 0; i < this.statistic.length; i++) {
        this.lineOption.series[0].data.push(this.statistic[i].users);
        this.lineOption.series[1].data.push(this.statistic[i].closedRequests);
        this.lineOption.series[2].data.push(this.statistic[i].newRequests);
        this.lineOption.series[3].data.push(this.statistic[i].closedInday);
        this.lineOption.series[4].data.push(this.statistic[i].likes);
        this.lineOption.series[5].data.push(this.statistic[i].dislikes);
        this.lineOption.series[6].data.push(this.statistic[i].reviews);
        for (let j = 0; j < this.practices.length; j++) {
          const practice = this.statistic[i].practiceIds.filter((p) => {
            return p === this.practices[j]._id;
          });
          this.barOption.series[j].data.push(practice.length);
        }
      }
    },
  },
};
</script>

<style lang="scss" scope>
.page-title {
  font-size: 42px;
  margin-top: 30px;
}
.chart-wrapper {
  min-width: 70vw;
  height: 500px;
}
.statistic-request-filter{
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px 0;
}
</style>