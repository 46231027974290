<template>
  <div class="projects">
    <h1 class="page-title">Проекти</h1>
    <div class="projects-wrapper">
      <div
        class="projects-item"
        v-for="(project, index) of projects"
        :key="index"
      >
        <span class="projects-item-title">
          {{ project.name }}
        </span>
        <span class="projects-item-description">
          {{ project.description }}
        </span>
        <div class="projects-item-row">
          <div class="projects-item-button">
            <el-button type="warning" @click="openUpdateModal(project)"
              >Змінити</el-button
            >
          </div>
          <div class="projects-item-button">
            <el-button type="danger" @click="deleteProject">Видалити</el-button>
          </div>
        </div>
      </div>
    </div>
    <TheFooter>
      <template slot="content">
        <el-button type="primary" @click="openCreateModal">Створити</el-button>
      </template>
    </TheFooter>
    <ProjectsModal
      :visible="projectModalVisible"
      :type="modalType"
      :users="users"
      :practices="practices"
      :currentProject="project"
      @close="closeModal"
      @save="saveProject"
      @update="updateProject"
      @openNewPracticeModal="openNewPracticeModal"
    />
    <PracticeModal
      :visible="practiceModalVisible"
      @save="savePractice"
      @close="closePracticeModal"
    />
  </div>
</template>

<script>
import TheFooter from "../base/TheFooter.vue";
import ProjectsModal from "./modals/ProjectsModal.vue";
import PracticeModal from "./modals/PracticeModal.vue";
export default {
  components: {
    TheFooter,
    ProjectsModal,
    PracticeModal,
  },
  data() {
    return {
      projects: [],
      users: [],
      practices: [],
      projectModalVisible: false,
      practiceModalVisible: false,
      modalType: "create",
      project:{}
    };
  },
  created() {
    this.getProjects();
    this.getSupports();
    this.getPractices();
  },
  methods: {
    async getProjects() {
      const { data } = await this.$http.get(`/api/project/all`);
      this.projects = data;
    },

    async getSupports() {
      const params = {
        filters: {
          role: ["support", "editor", "admin"],
        },
      };

      const { data } = await this.$http.get(`/api/user`, { params });
      this.users = data;
    },

    async getPractices() {
      const params = {
        filters: {
          status: "inactive",
          projectId: null,
        },
      };

      const { data } = await this.$http.get(`/api/practice`, { params });
      this.practices = data;
    },

    async saveProject(project) {
      const { data } = await this.$http.post(`/api/project/create`, {
        project,
      });
      if (data === "Ok") {
        this.projectModalVisible = false;
        this.getProjects();
        this.getPractices();
      }
    },

    async savePractice(data) {
      await this.$http.post(`/api/practice`, { name: data.name });
      if (!data.createAgain) {
        this.closePracticeModal();
      }
    },

    async updateProject(project){
      const { data } = await this.$http.put(`/api/project/update`, {
        project,
      });
      if (data === "Ok") {
        this.projectModalVisible = false;
        this.getProjects();
        this.getPractices();
      }
    },

    async deleteProject(){},

    openCreateModal() {
      this.projectModalVisible = true;
      this.modalType = "create";
    },
    closeModal() {
      this.currentProject = {};
      this.projectModalVisible = false;
    },
    openNewPracticeModal() {
      this.projectModalVisible = false;
      this.practiceModalVisible = true;
    },
    closePracticeModal() {
      this.getPractices();
      this.practiceModalVisible = false;
      this.projectModalVisible = true;
    },
    openUpdateModal(project){
      this.project = project;
      this.modalType = "update";
      this.projectModalVisible = true;
    }
  },
};
</script>

<style lang="scss">
.projects {
  padding: 20px;
}
.projects-wrapper{
  display: flex;
  flex-wrap: wrap;
}
.projects-item {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 150px;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.projects-item-title {
  font-size: 24px;
}
.projects-item-description {
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 290px;
}
.projects-item-row {
  width: calc(100% - 20px);
  display: flex;
  justify-content:space-between;
  padding: 10px;
}
</style>